export const BASE_API_URL = 'https://jpfprpyx88.execute-api.ap-southeast-2.amazonaws.com/production/'

export const STATUS = {
  in_progress: 'in_progress',
  open: 'open',
  closed: 'closed'
}

export const CATEGORIES = [
  {
    value: 'accommodation',
    label: 'Accommodation'
  },
  {
    value:'bullying_intimidation',
    label: 'Bullying, intimidation & harassment'
  },
  {
    value: 'discrimination',
    label: 'Discrimination'
  },
  {
    value: 'health_safety',
    label: 'Health & safety'
  },
  {
    value: 'pay_benefits',
    label: 'Pay & benefits'
  },
  {
    value: 'pollution',
    label: 'Pollution'
  },
  {
    value: 'unfair_dismissal',
    label: 'Unfair dismissal'
  },
  {
    value: 'modern_slavery',
    label: 'Modern slavery'
  },
  {
    value: 'working_conditions',
    label: 'Working conditions'
  }
]


export const COLOR = {
  PRIMARY: '#1A658F',
  CHART_RED: '#EB3300'
}
